var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('h3',{staticClass:"font-weight-bold blue-grey--text mt-2 font font-md font-weight-medium text-uppercase"},[_vm._v(" All Transactions ")]),_c('v-menu',{ref:"menu",staticClass:"font font-weight-medium",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto","nudge-left":120},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:" box-shadow-light mt-2 font text-uppercase font-weight-medium",attrs:{"label":"","close":_vm.dates.length === 2,"color":_vm.dates.length > 0 ? 'primary' : 'white'},on:{"click:close":function($event){(_vm.dates = []), (_vm.chosenDateRange = [])}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-chip',attrs,false),on),[_c('i',{staticClass:"material-icons-outlined font-size-md mr-2"},[_vm._v("event")]),_vm._v(" "+_vm._s(_vm.dates.length > 0 ? _vm.dateRangeText : "Choose date range")+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"font font-weight-medium font-size-sm",attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{staticClass:"font font-weight-medium",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){(_vm.menu = false), (_vm.date = []), (_vm.chosenDateRange = [])}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"font font-weight-medium",attrs:{"text":"","color":"primary"},on:{"click":_vm.setFilteredDate}},[_vm._v(" OK ")])],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end"},[_c('v-text-field',{staticClass:"box-shadow-light font font-sm",staticStyle:{"min-width":"400px"},attrs:{"solo":"","rounded":"","placeholder":"Search through transactions using account number ...","flat":"","append-icon":"search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 mt-1",attrs:{"loading":_vm.pageLoading,"small":"","fab":"","icon":""},on:{"click":function($event){return _vm.$store.dispatch('transactions/list', '?page=1&size=30')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:" font font-sm"},[_vm._v("Get latest transactions")])])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xl":"12"}},[_c('v-data-table',{staticClass:"font text-capitalize box-shadow-light mx-1 mt-n2",attrs:{"headers":_vm.headers,"items":_vm.transactions,"loading":_vm.pageLoading,"item-key":"branch","server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","footer-props":{
          itemsPerPageOptions: [30, 40, 50]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.meta",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":""}},[(item.meta && item.meta.accountName)?_c('span',{staticClass:" font-sm font-weight-medium"},[_vm._v(_vm._s(item.meta.accountName)+" ")]):_c('span',{staticClass:"font font-weight-medium font-sm"},[_vm._v(" N/A ")])])]}},{key:"item.totalAmount",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-sm font-weight-bold"},[_vm._v("GHC "+_vm._s(item.totalAmount ? item.totalAmount : "N/A")+" ")])]}},{key:"item.service",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:" font text-uppercase font-sm font-weight-medium"},[_vm._v(" "+_vm._s(item.meta.product)+" ")])])]}},{key:"item.paymentStatus",fn:function(ref){
        var item = ref.item;
return [(
              item.status &&
                item.status.toLowerCase() === 'completed'
            )?_c('v-chip',{attrs:{"label":"","small":"","color":"success"}},[_c('span',{staticClass:" font font-weight-medium font-sm text-uppercase"},[_vm._v(_vm._s(item.status))])]):(item.status && item.status.toLowerCase() === 'failed')?_c('v-chip',{attrs:{"color":"red","label":"","small":""}},[_c('span',{staticClass:" white--text font font-weight-medium font-sm text-uppercase"},[_vm._v(_vm._s(item.status))])]):_c('v-chip',{attrs:{"label":"","small":"","color":"orange"}},[_c('span',{staticClass:" white--text font font-weight-medium font-sm text-uppercase"},[_vm._v(_vm._s(item.paymentStatus ? item.paymentStatus : "N/A"))])])]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:" font font-weight-medium font-sm"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.createdAt))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }